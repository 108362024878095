import cn from 'classnames';
import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Components } from 'src/configs/components/components';
import { RootState } from '../../types/store-types';
import '../styles.scss';

type Props = {
  settings: any;
};
const HomePageConstructor: FC<Props> = ({ settings }) => {
  const { sectionLoading } = useSelector((state: RootState) => state.app);
  const renderHomePageWidgets = useCallback((): JSX.Element => {
    const orderIndex = {};
    Object.keys(settings).forEach((key: string) => (orderIndex[`${key}`] = settings[key].priority));

    const widgetLine = Object.keys(settings)
      .sort((a: string, b: string) => orderIndex[a] - orderIndex[b])
      .filter((key: string) => key !== 'styles')
      .map((key: string, index: number) => {
        return React.createElement(Components[key], {
          settings: settings[key],
          key: `${key}_${index}`,
        });
      });
    return <>{widgetLine}</>;
  }, []);

  return (
    <div
      className={cn('home_container', {
        ['is-load']: !sectionLoading.includes(false),
      })}
    >
      {renderHomePageWidgets()}
    </div>
  );
};
export default HomePageConstructor;
