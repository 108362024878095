import React, { FC, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { Components } from 'src/configs/components/components';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setSelectedProvider, setSelectedTag } from 'src/store/games/actions';
import '../styles.scss';

type Props = {
  settings: {
    firstSection: { [key: string]: any };
    secondSection: { [key: string]: any };
    thirdSection: { [key: string]: any };
  };
};

const apiHost = 'https://timelesstecheu-api-prod-bgsp.egtd-hr.com';
const CURRENCY = process.env.REACT_APP_CURRENCY;

const CategoryPageConstructor: FC<Props> = ({ settings }) => {
  const { pathname } = useLocation();

  const _setSelectedProvider = useConnectedAction(setSelectedProvider);
  const _setSelectedTag = useConnectedAction(setSelectedTag);

  const handleRedirect = (): void => {
    _setSelectedTag('allGames');
    _setSelectedProvider('EGT Digital');

    setTimeout(() => {
      const elem = document.getElementsByClassName(
        isMobileOnly ? 'providers_filter_mobile_container' : 'providers_accordion_container'
      )?.[0];

      if (elem) {
        elem.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    }, 0);
  };

  const renderCategoryPageWidgets = (line: any): JSX.Element => {
    const orderIndex: any = {};

    Object.keys(line).forEach((key: string) => (orderIndex[`${key}`] = line[key].priority));

    const widgetLine = Object.keys(line)
      .sort((a: string, b: string) => orderIndex[a] - orderIndex[b])
      .filter((key: string) => key !== 'styles')
      .map((key: string, index: number) => {
        return React.createElement(Components[key], {
          settings: line[key],
          key: `${key}_${index}`,
        });
      });

    return <>{widgetLine}</>;
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_EGT_WIDGETS === 'true' && localStorage.selectedCategory === 'casino') {
      const clover1 = document.getElementById('clover1');
      const bellLink = document.getElementById('bellLink');
      const jackpot = document.getElementById('jackpot');

      (window as any)?.EGTD_JackpotWidgetCloverChance({
        el: clover1,
        jackpot: 'Clover Chance',
        currency: CURRENCY,
        apiHost,
        type: isMobileOnly ? 'card' : 'horizontal',
      });
      (window as any)?.EGTD_JackpotWidgetBellLink({
        el: bellLink,
        jackpot: 'Clover Chance',
        currency: CURRENCY,
        apiHost,
        type: isMobileOnly ? 'card' : 'horizontal',
      });
      (window as any)?.EGTD_JackpotWidgetHighCash({
        el: jackpot,
        jackpot: 'High Cash',
        currency: CURRENCY,
        apiHost,
        type: isMobileOnly ? 'card' : 'horizontal',
      });
    }
  }, [pathname]);

  return (
    <div className={'page_container'}>
      <div className={'page_container_first_section'}>{renderCategoryPageWidgets(settings?.firstSection)}</div>
      {process.env.REACT_APP_ENABLE_EGT_WIDGETS === 'true' && localStorage.selectedCategory === 'casino' && (
        <>
          <div className="header">
            <p className="jackpot_title">Jackpots</p>
          </div>
          <div className="jackpots">
            <div className="jackpot_elem" id={'clover1'} onClick={handleRedirect} />
            <div className="jackpot_elem" id={'bellLink'} onClick={handleRedirect} />
            <div className="jackpot_elem" id={'jackpot'} onClick={handleRedirect} />
          </div>
        </>
      )}

      <div className={'page_container_second_section'} style={settings?.secondSection.styles}>
        {renderCategoryPageWidgets(settings?.secondSection)}
      </div>
      <div className={'page_container_third_section'}>{renderCategoryPageWidgets(settings?.thirdSection)}</div>
    </div>
  );
};
export default CategoryPageConstructor;
