import moment from 'moment';
import React, { FC, lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import CategoryPageConstructor from 'src/constructors/pageConstructors/CategoryPageConstructor';
import ContentPageConstructor from 'src/constructors/pageConstructors/ContentPageConstructor';
import HomePageConstructor from 'src/constructors/pageConstructors/HomePageConstructor';
import StaticPageConstructor from 'src/constructors/pageConstructors/StaticPagesConstructor';
import { isTokenExpired } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { refreshTokenRequest } from 'src/services/authorizationApi';
import { setSelectedCategory } from 'src/store/app/actions';
import { setSelectedProvider, setSelectedTag } from 'src/store/games/actions';
import { setLogout } from 'src/store/user/actions';
import { NavigationItem } from 'src/types/res-dto/categories-res';
import { RootState } from 'src/types/store-types';
import { ONE_HOUR, PAPIGAMES_AFFILATES } from 'src/utils/constants';
import Iframe from '../base-components/Iframe/Iframe';
import MainLayout from '../layouts/MainLayout';

const StaticHomePage = lazy(() => import('src/pages/StaticHomePage'));

const NotFoundPage = lazy(() => import('src/pages/NotFoundPage'));
type Route = {
  path: string;
  element: JSX.Element;
};
const Routing: FC = () => {
  const _logout = useConnectedAction(setLogout);
  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);
  const { user } = useSelector((state: RootState) => state.user);
  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { pageConfigs, navigation } = useSelector((state: RootState) => state.configs);

  const [defaultPage, setDefaultPage] = useState<JSX.Element | null>(null);

  const setNewStorageValues = (newUser: any, exp: number): void => {
    const now = moment();
    const storedDate = now.add(exp, 'seconds').valueOf();

    localStorage.setItem('user', JSON.stringify(newUser));
    localStorage.setItem('storedDate', storedDate.toString());
  };
  if (
    user &&
    localStorage.getItem('storedDate') &&
    Number(localStorage.getItem('storedDate')) - new Date().valueOf() < ONE_HOUR
  ) {
    refreshTokenRequest()
      .then((res) => {
        const newUser = { user, token: res.result.token };
        setNewStorageValues(newUser, res.result.exp);
      })
      .catch((error) => {
        console.error('Failed to refresh token', error);
        // Handle token refresh failure (e.g., logout the user, show an error message)
      });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderPage = (route: NavigationItem): any => {
    let component;
    let sections;
    let path;
    switch (route?.type) {
      case 'home':
        sections = pageConfigs.filter((f: { type: string }) => f.type === 'home')[0]?.sections;
        component =
          process.env.REACT_APP_HOME_PAGE_STATIC === 'true' ? (
            <StaticHomePage />
          ) : (
            <HomePageConstructor settings={sections} />
          );
        path = '/home';
        break;
      case 'static':
        component = <StaticPageConstructor page={route.name} />;
        path = route.name;
        break;
      case 'staticIframe':
      case 'liveSport':
      case 'sport':
      case 'tombala':
      case 'raceIframe':
      case 'pokerIframe':
      case 'cybersport':
      case 'fun-mission':
      case 'blogIframe':
      case 'casinoTournament':
        component = <Iframe type={route?.type} />;
        path = `/category/${route.name}`;
        break;
      case 'category':
        if (route?.subType === 'staticPage') {
          component = <StaticPageConstructor page={route.name} />;
        } else {
          sections = pageConfigs.filter((f: any) => f.type === route.type)[0]?.sections;
          component = <CategoryPageConstructor settings={sections} />;
        }

        path = `/category/${route.name}`;
        break;
      case 'content':
        sections = pageConfigs.filter((f: any) => f.type === route.type)[0]?.sections;
        component = <ContentPageConstructor settings={sections} />;
        path = `/content/${route.name}`;
        break;
      default:
        component = <></>;
        path = '';
    }

    if (route.isDefault) {
      if (route.type === 'category' && selectedCategory === 'home') {
        _setSelectedCategory(route.name);
        _setSelectedTag(route.hasHomeTag ? 'home' : 'allGames');
        _setSelectedProvider(['all_providers']);
      }

      setDefaultPage(component);
    }

    return {
      element: component,
      path,
    };
  };

  const routes: Route[] = useMemo(() => {
    const routesArr: Route[] = [];

    if (navigation && !!pageConfigs) {
      navigation?.forEach((route: NavigationItem) => {
        routesArr.push(renderPage(route));
      });
    }
    return routesArr;
  }, [navigation, pageConfigs, selectedCategory]);

  useEffect(() => {
    // TO DO must remove right side of condition if all users of V3 client move to V5
    if ((user?.storedDate && isTokenExpired(user?.storedDate)) || localStorage.getItem('token')) {
      _logout();
    }
  }, []);

  const openInExternalBrowser = (url: string): void => {
    window.location.href =
      'intent://' +
      url.substr(url.indexOf('://') + 3) +
      '#Intent;scheme=' +
      url.substr(0, url.indexOf('://')) +
      ';package=com.android.chrome;end;';
  };
  if (isAndroid && navigator.userAgent.includes('Instagram')) {
    openInExternalBrowser(window.location.href);
  }

  return (
    <Suspense fallback={<div />}>
      <Routes>
        {process.env.REACT_APP_WEBSITENAME === 'PapiGames' && (
          <>
            {PAPIGAMES_AFFILATES.map((affilate) => (
              <Route
                path={`/${affilate.name}`}
                key={affilate.id}
                element={<MainLayout children={defaultPage ?? routes[0]} />}
              />
            ))}
          </>
        )}
        <Route path={'/'} element={<MainLayout children={defaultPage ?? routes[0]} />} />

        {routes && !!routes.length && <Route path={'/*'} element={<NotFoundPage />} />}

        {routes?.map((m) => {
          return <Route key={m.path} path={m.path} element={<MainLayout children={m.element} />} />;
        })}
      </Routes>
    </Suspense>
  );
};
export default Routing;
