import cn from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from 'src/configs/icons';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setSelectedCategory } from 'src/store/app/actions';
import { Button } from '../../common/Button';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    classes: string;
    name: string;
  };
};
const BlogWidget: FC<Props> = ({ settings }) => {
  const { classes, name } = settings;
  const navigate = useNavigate();
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);

  const changeSelectedMenuItem = (item: string): void => {
    navigate(`/category/${item}`);
    _setSelectedCategory(item);
  };
  return (
    <Button className={cn(classes, 'blog-widget')} onClick={() => changeSelectedMenuItem(name)} icon={icons.blog}>
      Blog
    </Button>
  );
};
export default BlogWidget;
