/* eslint-disable max-len */

import { Country } from 'src/components/common/Country_Select/Country_Select';

/* eslint-ignore */
const SUCCESS_STATUSES = [200, 201, 204];

const APP_API_VERSION = '/api_v2';
const APP_API = '/api';

const CURRENCY_SYMBOLS = {
  USD: '$',
  ARS: '$',
  EUR: '€',
  TRY: '₺',
  TND: 'DT',
  INR: '₹',
  NPR: 'रु',
  BDT: '৳',
  BRL: 'R$',
  JPY: '¥',
  THB: '฿',
  PHP: '₱',
  KRW: '₩',
  CNY: 'CN¥',
  PYG: '₲',
  Gs: 'Gs',
};

const GAME_BOX_EL_LIST = {
  jackpot: 'jackpot',
  tournament: 'tournament',
  wagering: 'money',
  freespins: 'freespins',
};

const XPRESS_PROVIDER_WITH_REDIRECT_HOME = ['EVOLUTION', 'EvoPlay'];

const LANGUAGES_CODES = {
  en: 'en-US',
  tr: 'tr-TR',
  pt: 'pt-PT',
};

const VIEW_CATEGORY_TRANSFORM = {
  livesport: 'live',
  sport: 'sport',
};

const CARDEQUALSIZE = 148;

const ROWS = 160;
const COLS = 160;
const MOBILE_IMG_SIZE = 109;
const GAME_VISIABLE_COUNT_FOR_DESKTOP = 28;
const GAME_VISIABLE_COUNT_FOR_MOBILE = 27;

const GAME_CARD_SIZES = {
  lg: 335,
  md_l: 169,
  md_m: 162,
  md_s: 149,
  md_xs: 140,
  sm_l: 130,
  sm: 109,
};

const TEMPLATE_1_SLIDER_GAMES_COUNT = 16;
const TEMPLATE_2_SLIDER_GAMES_COUNT = 8;
const TEMPLATE_5_SLIDER_GAMES_COUNT = 8;

const SLIDER_MOBILE_COUNT = 3;

const WIN_SLIDER_COUNT_DESKTOP = 5;
const WIN_SLIDER_COUNT_MOBILE = 2;

const SLIDER_DEFAULT_MAX_COUNT = 15;
const GAME_TAGS_SCROLL_COUNT = 11;
const HAS_HOME_PAGE = true;

const IFRAMES = [
  'sport',
  'liveSport',
  'casinoTournament',
  'staticIframe',
  'betting',
  'silvaSeries',
  'tombala',
  'raceIframe',
  'dinhutech',
  'race',
  'pokerIframe',
  'fun-mission',
  'blogIframe',
  'blog',
  'cybersport',
];
const CATEGORIES = ['casino', 'liveCasino', 'betting', 'crashGames', 'virtualSport', 'casinoTournament', 'bingo'];

const SPORT_CATEGORIES = ['betting', 'liveSport'];

const NAVIGATION_GAMES_URLS = [{ category: 'bingo', gameId: 20934 }];

const PERSONAL_INFO_FIELD_DESKTOP = [
  'firstname',
  'countryCode',
  'lastname',
  'city',
  'birthDate',
  'address',
  'phoneNumber',
  'email',
];

const PERSONAL_INFO_FIELD_MOBILE = [
  'firstname',
  'lastname',
  'birthDate',
  'countryCode',
  'city',
  'address',
  'email',
  'phoneNumber',
];

const TABLE_CAPTION_ITEMS = ['date', 'status', 'ipAddress'];

const HISTORY_REQ_LIMIT = 8;

const TRANSACTIONS_HEADER = {
  movement: ['subType', 'dateUpdated', 'amount', 'status', 'details'],
  gameMovement: ['game_details', 'dateUpdated', 'amount', 'status'],
  casino: ['gameName', 'lastSpinDate', 'spinCount', 'winLoss'],
};
const TRANSACTIONS_MOVEMENTS = ['balanceTransfer', 'accountsTransfer'];
const GAME_MOVEMENTS = ['buyin', 'buyout'];

const CMS_CONVERTER = {
  contact_information: 1,
  privacy_policy: 2,
  responsible_gaming: 3,
  terms_and_conditions: 4,
  aml_and_kyc_policy: 11,
};

const CAROUSEL_DESKTOP_HEIGHT = 176;
const CAROUSEL_DESKTOP_MOBILE = 155;
const CAROUSEL_MOBILE_IMG_HEIGHT = 56;

const PAYMENT_TYPES: any = {
  deposit: 'DEPOSIT',
  withdrawel: 'withdraw',
};

const FIELD_OPTIONS = {
  nationalCardType: ['old', 'new'],
};

const REG_number = /[0-9\/]+/;

const enum EnumPaymentType {
  deposit = 'deposit',
  withdrawel = 'withdrawel',
}

const TRANSACTION_TYPES = [
  {
    name: 'movement',
    value: 'movement',
  },
  {
    name: 'gameMovement',
    value: 'gameMovement',
  },
];

const PERIOD_FILTER_TYPES: any[] = [
  { name: 'last_24h', value: 'day' },
  { name: 'last_week', value: 'week' },
  { name: 'last_month', value: 'month' },
  { name: 'manual', value: 'manual' },
];

const SILVER_CASINO_CLOSE_MESSAGE = ['close', 'closeGame', 'notifyCloseContainer', 'GAME_MODE:LOBBY'];

const CMS_BANNERS_CATEGORY_CONVERT = {
  regular: 'casino',
  mobileRegular: 'mobile_casino',
  live: 'liveCasino',
  mobileLive: 'mobile_liveCasino',
  home: 'home',
  mobileHome: 'mobile_home',
  virtual: 'virtualSport',
  mobileCrashGames: 'mobile_crashGames',
  crashGames: 'crashGames',
  mobileVirtual: 'mobile_virtualSport',
  mobileLivelotto: 'mobile_livelotto',
  mobileRace: 'mobile_race',
};

const PAGES_WITHOUT_MOBILE_FIXED_MENU = ['tournament'];

const TYPOGRAPHY_VARIATIONS = {
  subtitle1: 'h6',
  subtitle2: 'h6',
  subtitle3: 'h6',
  inherit: 'p',
  body1: 'p',
  body2: 'p',
  body3: 'p',
  body4: 'p',
  body5: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

const SIGN_UP_PLACEHOLDERS = ['placeholder', 'label', 'errorMessage', 'styles'];

enum EnumLangCodes {
  en = 'en',
  pt = 'pt-BR',
  tr = 'tr',
  es = 'es',
  kr = 'ko-KR',
  it = 'it',
  de = 'de',
  cn = 'zh-CN',
}
// TO DO replace this part and make it dinamic
const STATIC_IFRAME_URL: any =
  process.env.REACT_APP_WEBSITENAME === 'PapiGames'
    ? {
        silvaSeries: 'https://betpassion.info/landing-papi-game/',
        dinhutech: 'https://dinhutech.papipromo.com.br/regras/',
      }
    : {
        silvaSeries: 'https://betpassion.info/landing-papi-game/',
      };

const MOBILE_MENU_PROMOTIONS =
  process.env.REACT_APP_WEBSITENAME === 'PapiGames'
    ? [
        {
          promo: 'casinoTournament',
          needLogIn: false,
        },
        {
          promo: 'dinhutech',
          needLogIn: false,
        },
      ]
    : [
        {
          promo: 'casinoTournament',
          needLogIn: false,
        },
      ];

const THEMES = ['dark', 'light'];

const PRIZE_DISTRIBUTION_TABLE_CAPTIONS = ['level', 'pp', 'friend_prize', 'your_prize'];

const GENERATE_LINK_SOCIAL_ICONS = ['facebook', 'twitter', 'whatsapp'];

const SOCIAL_MEDIA_SHARER: any = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  twitter: 'https://twitter.com/intent/tweet?text=',
  whatsapp: 'https://api.whatsapp.com/send?text=',
};

const HOME_WIDGET = { btnStyles: 'success-filled-btn', cls: 'home_widget' };
const CATEGORY_WIDGET = { btnStyles: ' success-filled-btn', cls: 'category_widget' };

const WIDGET_STYLES_FOR_2_3 = [
  HOME_WIDGET,
  HOME_WIDGET,
  CATEGORY_WIDGET,
  CATEGORY_WIDGET,
  CATEGORY_WIDGET,
  HOME_WIDGET,
];
const WIDGET_STYLES_FOR_3_1 = [HOME_WIDGET, HOME_WIDGET, HOME_WIDGET, CATEGORY_WIDGET];

const DEFAULT_INACTIVITY_TIME = 18000000; // 300m * 60s * 1000ms

const BASE_URL = window.location.origin + '/';

const TEMPLATES_WITH_DETAILS = ['template_fullScreen'];

const TEMPLATE_RADIUS: any = {
  template_noFullScreen: '16px',
  template_fullScreen: '16px',
  template_with_sidebar: '8px',
};

const TEMPLATE_BANNNER_RADIUS: any = {
  template_noFullScreen: '16px',
  template_fullScreen: '0',
  template_with_sidebar: '0',
};

const CAROUSEL_DESKTOP_IMG_HEIGHT = {
  template_noFullScreen: 26,
  template_fullScreen: 25,
  template_with_sidebar: 16,
};

const COUNTRY_LIST: Country[] = [
  {
    label: 'American Samoa',
    value: 'ASM',
    iconSrc: 'https://flagcdn.com/w320/as.png',
    code: '+1684',
  },
  {
    label: 'Peru',
    value: 'PER',
    iconSrc: 'https://flagcdn.com/w320/pe.png',
    code: '+51',
  },
  {
    label: 'Tonga',
    value: 'TON',
    iconSrc: 'https://flagcdn.com/w320/to.png',
    code: '+676',
  },
  {
    label: 'Mayotte',
    value: 'MYT',
    iconSrc: 'https://flagcdn.com/w320/yt.png',
    code: '+262',
  },
  {
    label: 'Panama',
    value: 'PAN',
    iconSrc: 'https://flagcdn.com/w320/pa.png',
    code: '+507',
  },
  {
    label: 'Madagascar',
    value: 'MDG',
    iconSrc: 'https://flagcdn.com/w320/mg.png',
    code: '+261',
  },
  {
    label: 'Belgium',
    value: 'BEL',
    iconSrc: 'https://flagcdn.com/w320/be.png',
    code: '+32',
  },
  {
    label: 'Central African Republic',
    value: 'CAF',
    iconSrc: 'https://flagcdn.com/w320/cf.png',
    code: '+236',
  },
  {
    label: 'Hungary',
    value: 'HUN',
    iconSrc: 'https://flagcdn.com/w320/hu.png',
    code: '+36',
  },
  {
    label: 'Mongolia',
    value: 'MNG',
    iconSrc: 'https://flagcdn.com/w320/mn.png',
    code: '+976',
  },
  {
    label: 'Saudi Arabia',
    value: 'SAU',
    iconSrc: 'https://flagcdn.com/w320/sa.png',
    code: '+966',
  },
  {
    label: 'Eswatini',
    value: 'SWZ',
    iconSrc: 'https://flagcdn.com/w320/sz.png',
    code: '+268',
  },
  {
    label: 'Uganda',
    value: 'UGA',
    iconSrc: 'https://flagcdn.com/w320/ug.png',
    code: '+256',
  },
  {
    label: 'Paraguay',
    value: 'PRY',
    iconSrc: 'https://flagcdn.com/w320/py.png',
    code: '+595',
  },
  {
    label: 'Cameroon',
    value: 'CMR',
    iconSrc: 'https://flagcdn.com/w320/cm.png',
    code: '+237',
  },
  {
    label: 'France',
    value: 'FRA',
    iconSrc: 'https://flagcdn.com/w320/fr.png',
    code: '+33',
  },
  {
    label: 'Togo',
    value: 'TGO',
    iconSrc: 'https://flagcdn.com/w320/tg.png',
    code: '+228',
  },
  {
    label: 'Mauritius',
    value: 'MUS',
    iconSrc: 'https://flagcdn.com/w320/mu.png',
    code: '+230',
  },
  {
    label: 'Cook Islands',
    value: 'COK',
    iconSrc: 'https://flagcdn.com/w320/ck.png',
    code: '+682',
  },
  {
    label: 'Palau',
    value: 'PLW',
    iconSrc: 'https://flagcdn.com/w320/pw.png',
    code: '+680',
  },
  {
    label: 'Nepal',
    value: 'NPL',
    iconSrc: 'https://flagcdn.com/w320/np.png',
    code: '+977',
  },
  {
    label: 'New Zealand',
    value: 'NZL',
    iconSrc: 'https://flagcdn.com/w320/nz.png',
    code: '+64',
  },
  {
    label: 'Pitcairn Islands',
    value: 'PCN',
    iconSrc: 'https://flagcdn.com/w320/pn.png',
    code: '+64',
  },
  {
    label: 'Seychelles',
    value: 'SYC',
    iconSrc: 'https://flagcdn.com/w320/sc.png',
    code: '+248',
  },
  {
    label: 'United Arab Emirates',
    value: 'ARE',
    iconSrc: 'https://flagcdn.com/w320/ae.png',
    code: '+971',
  },
  {
    label: 'Belarus',
    value: 'BLR',
    iconSrc: 'https://flagcdn.com/w320/by.png',
    code: '+375',
  },
  {
    label: 'Dominican Republic',
    value: 'DOM',
    iconSrc: 'https://flagcdn.com/w320/do.png',
    code: '+1809,829,849',
  },
  {
    label: 'Libya',
    value: 'LBY',
    iconSrc: 'https://flagcdn.com/w320/ly.png',
    code: '+218',
  },
  {
    label: 'Tokelau',
    value: 'TKL',
    iconSrc: 'https://flagcdn.com/w320/tk.png',
    code: '+690',
  },
  {
    label: 'Croatia',
    value: 'HRV',
    iconSrc: 'https://flagcdn.com/w320/hr.png',
    code: '+385',
  },
  {
    label: 'Philippines',
    value: 'PHL',
    iconSrc: 'https://flagcdn.com/w320/ph.png',
    code: '+63',
  },
  {
    label: 'Albania',
    value: 'ALB',
    iconSrc: 'https://flagcdn.com/w320/al.png',
    code: '+355',
  },
  {
    label: 'Bouvet Island',
    value: 'BVT',
    iconSrc: 'https://flagcdn.com/w320/bv.png',
    code: '+47',
  },
  {
    label: 'Indonesia',
    value: 'IDN',
    iconSrc: 'https://flagcdn.com/w320/id.png',
    code: '+62',
  },
  {
    label: 'Thailand',
    value: 'THA',
    iconSrc: 'https://flagcdn.com/w320/th.png',
    code: '+66',
  },
  {
    label: 'Latvia',
    value: 'LVA',
    iconSrc: 'https://flagcdn.com/w320/lv.png',
    code: '+371',
  },
  {
    label: 'Ecuador',
    value: 'ECU',
    iconSrc: 'https://flagcdn.com/w320/ec.png',
    code: '+593',
  },
  {
    label: 'Kenya',
    value: 'KEN',
    iconSrc: 'https://flagcdn.com/w320/ke.png',
    code: '+254',
  },
  {
    label: 'Liberia',
    value: 'LBR',
    iconSrc: 'https://flagcdn.com/w320/lr.png',
    code: '+231',
  },
  {
    label: 'British Virgin Islands',
    value: 'VGB',
    iconSrc: 'https://flagcdn.com/w320/vg.png',
    code: '+1284',
  },
  {
    label: 'San Marino',
    value: 'SMR',
    iconSrc: 'https://flagcdn.com/w320/sm.png',
    code: '+378',
  },
  {
    label: 'India',
    value: 'IND',
    iconSrc: 'https://flagcdn.com/w320/in.png',
    code: '+91',
  },
  {
    label: 'Bahrain',
    value: 'BHR',
    iconSrc: 'https://flagcdn.com/w320/bh.png',
    code: '+973',
  },
  {
    label: 'Malawi',
    value: 'MWI',
    iconSrc: 'https://flagcdn.com/w320/mw.png',
    code: '+265',
  },
  {
    label: 'Iceland',
    value: 'ISL',
    iconSrc: 'https://flagcdn.com/w320/is.png',
    code: '+354',
  },
  {
    label: 'Brunei',
    value: 'BRN',
    iconSrc: 'https://flagcdn.com/w320/bn.png',
    code: '+673',
  },
  {
    label: 'French Guiana',
    value: 'GUF',
    iconSrc: 'https://flagcdn.com/w320/gf.png',
    code: '+594',
  },
  {
    label: 'Samoa',
    value: 'WSM',
    iconSrc: 'https://flagcdn.com/w320/ws.png',
    code: '+685',
  },
  {
    label: 'Georgia',
    value: 'GEO',
    iconSrc: 'https://flagcdn.com/w320/ge.png',
    code: '+995',
  },
  {
    label: 'Caribbean Netherlands',
    value: 'BES',
    iconSrc: 'https://flagcdn.com/w320/bq.png',
    code: '+599',
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'HMD',
    iconSrc: 'https://flagcdn.com/w320/hm.png',
    code: null,
  },
  {
    label: 'Papua New Guinea',
    value: 'PNG',
    iconSrc: 'https://flagcdn.com/w320/pg.png',
    code: '+675',
  },
  {
    label: 'Ghana',
    value: 'GHA',
    iconSrc: 'https://flagcdn.com/w320/gh.png',
    code: '+233',
  },
  {
    label: 'Afghanistan',
    value: 'AFG',
    iconSrc:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png',
    code: '+93',
  },
  {
    label: 'Costa Rica',
    value: 'CRI',
    iconSrc: 'https://flagcdn.com/w320/cr.png',
    code: '+506',
  },
  {
    label: 'Fiji',
    value: 'FJI',
    iconSrc: 'https://flagcdn.com/w320/fj.png',
    code: '+679',
  },
  {
    label: 'South Korea',
    value: 'KOR',
    iconSrc: 'https://flagcdn.com/w320/kr.png',
    code: '+82',
  },
  {
    label: 'Taiwan',
    value: 'TWN',
    iconSrc: 'https://flagcdn.com/w320/tw.png',
    code: '+886',
  },
  {
    label: 'Czechia',
    value: 'CZE',
    iconSrc: 'https://flagcdn.com/w320/cz.png',
    code: '+420',
  },
  {
    label: 'Nicaragua',
    value: 'NIC',
    iconSrc: 'https://flagcdn.com/w320/ni.png',
    code: '+505',
  },
  {
    label: 'Portugal',
    value: 'PRT',
    iconSrc: 'https://flagcdn.com/w320/pt.png',
    code: '+351',
  },
  {
    label: 'Chile',
    value: 'CHL',
    iconSrc: 'https://flagcdn.com/w320/cl.png',
    code: '+56',
  },
  {
    label: 'Gambia',
    value: 'GMB',
    iconSrc: 'https://flagcdn.com/w320/gm.png',
    code: '+220',
  },
  {
    label: 'Belize',
    value: 'BLZ',
    iconSrc: 'https://flagcdn.com/w320/bz.png',
    code: '+501',
  },
  {
    label: 'Maldives',
    value: 'MDV',
    iconSrc: 'https://flagcdn.com/w320/mv.png',
    code: '+960',
  },
  {
    label: 'Vanuatu',
    value: 'VUT',
    iconSrc: 'https://flagcdn.com/w320/vu.png',
    code: '+678',
  },
  {
    label: 'Malaysia',
    value: 'MYS',
    iconSrc: 'https://flagcdn.com/w320/my.png',
    code: '+60',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'ATG',
    iconSrc: 'https://flagcdn.com/w320/ag.png',
    code: '+1268',
  },
  {
    label: 'Iran',
    value: 'IRN',
    iconSrc: 'https://flagcdn.com/w320/ir.png',
    code: '+98',
  },
  {
    label: 'Russia',
    value: 'RUS',
    iconSrc: 'https://flagcdn.com/w320/ru.png',
    code: '+73,4,5,8,9',
  },
  {
    label: 'Kiribati',
    value: 'KIR',
    iconSrc: 'https://flagcdn.com/w320/ki.png',
    code: '+686',
  },
  {
    label: 'Martinique',
    value: 'MTQ',
    iconSrc: 'https://flagcdn.com/w320/mq.png',
    code: '+596',
  },
  {
    label: 'Bangladesh',
    value: 'BGD',
    iconSrc: 'https://flagcdn.com/w320/bd.png',
    code: '+880',
  },
  {
    label: 'Armenia',
    value: 'ARM',
    iconSrc: 'https://flagcdn.com/w320/am.png',
    code: '+374',
  },
  {
    label: 'Mexico',
    value: 'MEX',
    iconSrc: 'https://flagcdn.com/w320/mx.png',
    code: '+52',
  },
  {
    label: 'Estonia',
    value: 'EST',
    iconSrc: 'https://flagcdn.com/w320/ee.png',
    code: '+372',
  },
  {
    label: 'Oman',
    value: 'OMN',
    iconSrc: 'https://flagcdn.com/w320/om.png',
    code: '+968',
  },
  {
    label: 'United States',
    value: 'USA',
    iconSrc: 'https://flagcdn.com/w320/us.png',
    code: '+1201,202,203,205,206,207,208,209,210,212,213,214,215,216,217,218,219,220,224,225,227,228,229,231,234,239,240,248,251,252,253,254,256,260,262,267,269,270,272,274,276,281,283,301,302,303,304,305,307,308,309,310,312,313,314,315,316,317,318,319,320,321,323,325,327,330,331,334,336,337,339,346,347,351,352,360,361,364,380,385,386,401,402,404,405,406,407,408,409,410,412,413,414,415,417,419,423,424,425,430,432,434,435,440,442,443,447,458,463,464,469,470,475,478,479,480,484,501,502,503,504,505,507,508,509,510,512,513,515,516,517,518,520,530,531,534,539,540,541,551,559,561,562,563,564,567,570,571,573,574,575,580,585,586,601,602,603,605,606,607,608,609,610,612,614,615,616,617,618,619,620,623,626,628,629,630,631,636,641,646,650,651,657,660,661,662,667,669,678,681,682,701,702,703,704,706,707,708,712,713,714,715,716,717,718,719,720,724,725,727,730,731,732,734,737,740,743,747,754,757,760,762,763,765,769,770,772,773,774,775,779,781,785,786,801,802,803,804,805,806,808,810,812,813,814,815,816,817,818,828,830,831,832,843,845,847,848,850,854,856,857,858,859,860,862,863,864,865,870,872,878,901,903,904,906,907,908,909,910,912,913,914,915,916,917,918,919,920,925,928,929,930,931,934,936,937,938,940,941,947,949,951,952,954,956,959,970,971,972,973,975,978,979,980,984,985,989',
  },
  {
    label: 'Liechtenstein',
    value: 'LIE',
    iconSrc: 'https://flagcdn.com/w320/li.png',
    code: '+423',
  },
  {
    label: 'Bahamas',
    value: 'BHS',
    iconSrc: 'https://flagcdn.com/w320/bs.png',
    code: '+1242',
  },
  {
    label: 'Benin',
    value: 'BEN',
    iconSrc: 'https://flagcdn.com/w320/bj.png',
    code: '+229',
  },
  {
    label: 'Kosovo',
    value: 'UNK',
    iconSrc: 'https://flagcdn.com/w320/xk.png',
    code: '+383',
  },
  {
    label: 'Tuvalu',
    value: 'TUV',
    iconSrc: 'https://flagcdn.com/w320/tv.png',
    code: '+688',
  },
  {
    label: 'Faroe Islands',
    value: 'FRO',
    iconSrc: 'https://flagcdn.com/w320/fo.png',
    code: '+298',
  },
  {
    label: 'Cayman Islands',
    value: 'CYM',
    iconSrc: 'https://flagcdn.com/w320/ky.png',
    code: '+1345',
  },
  {
    label: 'Egypt',
    value: 'EGY',
    iconSrc: 'https://flagcdn.com/w320/eg.png',
    code: '+20',
  },
  {
    label: 'Azerbaijan',
    value: 'AZE',
    iconSrc: 'https://flagcdn.com/w320/az.png',
    code: '+994',
  },
  {
    label: 'Australia',
    value: 'AUS',
    iconSrc: 'https://flagcdn.com/w320/au.png',
    code: '+61',
  },
  {
    label: 'Isle of Man',
    value: 'IMN',
    iconSrc: 'https://flagcdn.com/w320/im.png',
    code: '+44',
  },
  {
    label: 'Ireland',
    value: 'IRL',
    iconSrc: 'https://flagcdn.com/w320/ie.png',
    code: '+353',
  },
  {
    label: 'Barbados',
    value: 'BRB',
    iconSrc: 'https://flagcdn.com/w320/bb.png',
    code: '+1246',
  },
  {
    label: 'El Salvador',
    value: 'SLV',
    iconSrc: 'https://flagcdn.com/w320/sv.png',
    code: '+503',
  },
  {
    label: 'Guatemala',
    value: 'GTM',
    iconSrc: 'https://flagcdn.com/w320/gt.png',
    code: '+502',
  },
  {
    label: 'Japan',
    value: 'JPN',
    iconSrc: 'https://flagcdn.com/w320/jp.png',
    code: '+81',
  },
  {
    label: 'Vietnam',
    value: 'VNM',
    iconSrc: 'https://flagcdn.com/w320/vn.png',
    code: '+84',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MNP',
    iconSrc: 'https://flagcdn.com/w320/mp.png',
    code: '+1670',
  },
  {
    label: 'Lithuania',
    value: 'LTU',
    iconSrc: 'https://flagcdn.com/w320/lt.png',
    code: '+370',
  },
  {
    label: 'Saint Lucia',
    value: 'LCA',
    iconSrc: 'https://flagcdn.com/w320/lc.png',
    code: '+1758',
  },
  {
    label: 'Hong Kong',
    value: 'HKG',
    iconSrc: 'https://flagcdn.com/w320/hk.png',
    code: '+852',
  },
  {
    label: 'Angola',
    value: 'AGO',
    iconSrc: 'https://flagcdn.com/w320/ao.png',
    code: '+244',
  },
  {
    label: 'Mozambique',
    value: 'MOZ',
    iconSrc: 'https://flagcdn.com/w320/mz.png',
    code: '+258',
  },
  {
    label: 'Botswana',
    value: 'BWA',
    iconSrc: 'https://flagcdn.com/w320/bw.png',
    code: '+267',
  },
  {
    label: 'Germany',
    value: 'DEU',
    iconSrc: 'https://flagcdn.com/w320/de.png',
    code: '+49',
  },
  {
    label: 'Syria',
    value: 'SYR',
    iconSrc: 'https://flagcdn.com/w320/sy.png',
    code: '+963',
  },
  {
    label: 'Moldova',
    value: 'MDA',
    iconSrc: 'https://flagcdn.com/w320/md.png',
    code: '+373',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'SPM',
    iconSrc: 'https://flagcdn.com/w320/pm.png',
    code: '+508',
  },
  {
    label: 'Guernsey',
    value: 'GGY',
    iconSrc: 'https://flagcdn.com/w320/gg.png',
    code: '+44',
  },
  {
    label: 'Wallis and Futuna',
    value: 'WLF',
    iconSrc: 'https://flagcdn.com/w320/wf.png',
    code: '+681',
  },
  {
    label: 'Poland',
    value: 'POL',
    iconSrc: 'https://flagcdn.com/w320/pl.png',
    code: '+48',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TTO',
    iconSrc: 'https://flagcdn.com/w320/tt.png',
    code: '+1868',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IOT',
    iconSrc: 'https://flagcdn.com/w320/io.png',
    code: '+246',
  },
  {
    label: 'Marshall Islands',
    value: 'MHL',
    iconSrc: 'https://flagcdn.com/w320/mh.png',
    code: '+692',
  },
  {
    label: 'Austria',
    value: 'AUT',
    iconSrc: 'https://flagcdn.com/w320/at.png',
    code: '+43',
  },
  {
    label: 'Kazakhstan',
    value: 'KAZ',
    iconSrc: 'https://flagcdn.com/w320/kz.png',
    code: '+76,7',
  },
  {
    label: 'Aruba',
    value: 'ABW',
    iconSrc: 'https://flagcdn.com/w320/aw.png',
    code: '+297',
  },
  {
    label: 'South Africa',
    value: 'ZAF',
    iconSrc: 'https://flagcdn.com/w320/za.png',
    code: '+27',
  },
  {
    label: 'Denmark',
    value: 'DNK',
    iconSrc: 'https://flagcdn.com/w320/dk.png',
    code: '+45',
  },
  {
    label: 'Suriname',
    value: 'SUR',
    iconSrc: 'https://flagcdn.com/w320/sr.png',
    code: '+597',
  },
  {
    label: 'Bermuda',
    value: 'BMU',
    iconSrc: 'https://flagcdn.com/w320/bm.png',
    code: '+1441',
  },
  {
    label: 'Montserrat',
    value: 'MSR',
    iconSrc: 'https://flagcdn.com/w320/ms.png',
    code: '+1664',
  },
  {
    label: 'Ivory Coast',
    value: 'CIV',
    iconSrc: 'https://flagcdn.com/w320/ci.png',
    code: '+225',
  },
  {
    label: 'Mauritania',
    value: 'MRT',
    iconSrc: 'https://flagcdn.com/w320/mr.png',
    code: '+222',
  },
  {
    label: 'Sierra Leone',
    value: 'SLE',
    iconSrc: 'https://flagcdn.com/w320/sl.png',
    code: '+232',
  },
  {
    label: 'Guinea',
    value: 'GIN',
    iconSrc: 'https://flagcdn.com/w320/gn.png',
    code: '+224',
  },
  {
    label: 'French Polynesia',
    value: 'PYF',
    iconSrc: 'https://flagcdn.com/w320/pf.png',
    code: '+689',
  },
  {
    label: 'Pakistan',
    value: 'PAK',
    iconSrc: 'https://flagcdn.com/w320/pk.png',
    code: '+92',
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'UMI',
    iconSrc: 'https://flagcdn.com/w320/um.png',
    code: '+268',
  },
  {
    label: 'Lesotho',
    value: 'LSO',
    iconSrc: 'https://flagcdn.com/w320/ls.png',
    code: '+266',
  },
  {
    label: 'Senegal',
    value: 'SEN',
    iconSrc: 'https://flagcdn.com/w320/sn.png',
    code: '+221',
  },
  {
    label: 'Tajikistan',
    value: 'TJK',
    iconSrc: 'https://flagcdn.com/w320/tj.png',
    code: '+992',
  },
  {
    label: 'Switzerland',
    value: 'CHE',
    iconSrc: 'https://flagcdn.com/w320/ch.png',
    code: '+41',
  },
  {
    label: 'Eritrea',
    value: 'ERI',
    iconSrc: 'https://flagcdn.com/w320/er.png',
    code: '+291',
  },
  {
    label: 'French Southern and Antarctic Lands',
    value: 'ATF',
    iconSrc: 'https://flagcdn.com/w320/tf.png',
    code: '+262',
  },
  {
    label: 'Jersey',
    value: 'JEY',
    iconSrc: 'https://flagcdn.com/w320/je.png',
    code: '+44',
  },
  {
    label: 'Cambodia',
    value: 'KHM',
    iconSrc: 'https://flagcdn.com/w320/kh.png',
    code: '+855',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VCT',
    iconSrc: 'https://flagcdn.com/w320/vc.png',
    code: '+1784',
  },
  {
    label: 'Sint Maarten',
    value: 'SXM',
    iconSrc: 'https://flagcdn.com/w320/sx.png',
    code: '+1721',
  },
  {
    label: 'Vatican City',
    value: 'VAT',
    iconSrc: 'https://flagcdn.com/w320/va.png',
    code: '+3906698,79',
  },
  {
    label: 'Uruguay',
    value: 'URY',
    iconSrc: 'https://flagcdn.com/w320/uy.png',
    code: '+598',
  },
  {
    label: 'Western Sahara',
    value: 'ESH',
    iconSrc: 'https://flagcdn.com/w320/eh.png',
    code: '+2125288,125289',
  },
  {
    label: 'Cape Verde',
    value: 'CPV',
    iconSrc: 'https://flagcdn.com/w320/cv.png',
    code: '+238',
  },
  {
    label: 'Italy',
    value: 'ITA',
    iconSrc: 'https://flagcdn.com/w320/it.png',
    code: '+39',
  },
  {
    label: 'United Kingdom',
    value: 'GBR',
    iconSrc: 'https://flagcdn.com/w320/gb.png',
    code: '+44',
  },
  {
    label: 'Macau',
    value: 'MAC',
    iconSrc: 'https://flagcdn.com/w320/mo.png',
    code: '+853',
  },
  {
    label: 'Guyana',
    value: 'GUY',
    iconSrc: 'https://flagcdn.com/w320/gy.png',
    code: '+592',
  },
  {
    label: 'Norway',
    value: 'NOR',
    iconSrc: 'https://flagcdn.com/w320/no.png',
    code: '+47',
  },
  {
    label: 'New Caledonia',
    value: 'NCL',
    iconSrc: 'https://flagcdn.com/w320/nc.png',
    code: '+687',
  },
  {
    label: 'South Sudan',
    value: 'SSD',
    iconSrc: 'https://flagcdn.com/w320/ss.png',
    code: '+211',
  },
  {
    label: 'Burkina Faso',
    value: 'BFA',
    iconSrc: 'https://flagcdn.com/w320/bf.png',
    code: '+226',
  },
  {
    label: 'Guadeloupe',
    value: 'GLP',
    iconSrc: 'https://flagcdn.com/w320/gp.png',
    code: '+590',
  },
  {
    label: 'Lebanon',
    value: 'LBN',
    iconSrc: 'https://flagcdn.com/w320/lb.png',
    code: '+961',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KNA',
    iconSrc: 'https://flagcdn.com/w320/kn.png',
    code: '+1869',
  },
  {
    label: 'Kuwait',
    value: 'KWT',
    iconSrc: 'https://flagcdn.com/w320/kw.png',
    code: '+965',
  },
  {
    label: 'South Georgia',
    value: 'SGS',
    iconSrc: 'https://flagcdn.com/w320/gs.png',
    code: '+500',
  },
  {
    label: 'Luxembourg',
    value: 'LUX',
    iconSrc: 'https://flagcdn.com/w320/lu.png',
    code: '+352',
  },
  {
    label: 'Myanmar',
    value: 'MMR',
    iconSrc: 'https://flagcdn.com/w320/mm.png',
    code: '+95',
  },
  {
    label: 'Israel',
    value: 'ISR',
    iconSrc: 'https://flagcdn.com/w320/il.png',
    code: '+972',
  },
  {
    label: 'Turkmenistan',
    value: 'TKM',
    iconSrc: 'https://flagcdn.com/w320/tm.png',
    code: '+993',
  },
  {
    label: 'Greece',
    value: 'GRC',
    iconSrc: 'https://flagcdn.com/w320/gr.png',
    code: '+30',
  },
  {
    label: 'Monaco',
    value: 'MCO',
    iconSrc: 'https://flagcdn.com/w320/mc.png',
    code: '+377',
  },
  {
    label: 'Venezuela',
    value: 'VEN',
    iconSrc: 'https://flagcdn.com/w320/ve.png',
    code: '+58',
  },
  {
    label: 'Republic of the Congo',
    value: 'COG',
    iconSrc: 'https://flagcdn.com/w320/cg.png',
    code: '+242',
  },
  {
    label: 'Spain',
    value: 'ESP',
    iconSrc: 'https://flagcdn.com/w320/es.png',
    code: '+34',
  },
  {
    label: 'Norfolk Island',
    value: 'NFK',
    iconSrc: 'https://flagcdn.com/w320/nf.png',
    code: '+672',
  },
  {
    label: 'Dominica',
    value: 'DMA',
    iconSrc: 'https://flagcdn.com/w320/dm.png',
    code: '+1767',
  },
  {
    label: 'Tunisia',
    value: 'TUN',
    iconSrc: 'https://flagcdn.com/w320/tn.png',
    code: '+216',
  },
  {
    label: 'Turkey',
    value: 'TUR',
    iconSrc: 'https://flagcdn.com/w320/tr.png',
    code: '+90',
  },
  {
    label: 'Colombia',
    value: 'COL',
    iconSrc: 'https://flagcdn.com/w320/co.png',
    code: '+57',
  },
  {
    label: 'Comoros',
    value: 'COM',
    iconSrc: 'https://flagcdn.com/w320/km.png',
    code: '+269',
  },
  {
    label: 'Solomon Islands',
    value: 'SLB',
    iconSrc: 'https://flagcdn.com/w320/sb.png',
    code: '+677',
  },
  {
    label: 'Saint Barthélemy',
    value: 'BLM',
    iconSrc: 'https://flagcdn.com/w320/bl.png',
    code: '+590',
  },
  {
    label: 'Uzbekistan',
    value: 'UZB',
    iconSrc: 'https://flagcdn.com/w320/uz.png',
    code: '+998',
  },
  {
    label: 'Palestine',
    value: 'PSE',
    iconSrc: 'https://flagcdn.com/w320/ps.png',
    code: '+970',
  },
  {
    label: 'Antarctica',
    value: 'ATA',
    iconSrc: 'https://flagcdn.com/w320/aq.png',
    code: null,
  },
  {
    label: 'Cuba',
    value: 'CUB',
    iconSrc: 'https://flagcdn.com/w320/cu.png',
    code: '+53',
  },
  {
    label: 'Gabon',
    value: 'GAB',
    iconSrc: 'https://flagcdn.com/w320/ga.png',
    code: '+241',
  },
  {
    label: 'Kyrgyzstan',
    value: 'KGZ',
    iconSrc: 'https://flagcdn.com/w320/kg.png',
    code: '+996',
  },
  {
    label: 'Micronesia',
    value: 'FSM',
    iconSrc: 'https://flagcdn.com/w320/fm.png',
    code: '+691',
  },
  {
    label: 'Sweden',
    value: 'SWE',
    iconSrc: 'https://flagcdn.com/w320/se.png',
    code: '+46',
  },
  {
    label: 'Saint Martin',
    value: 'MAF',
    iconSrc: 'https://flagcdn.com/w320/mf.png',
    code: '+590',
  },
  {
    label: 'Slovakia',
    value: 'SVK',
    iconSrc: 'https://flagcdn.com/w320/sk.png',
    code: '+421',
  },
  {
    label: 'DR Congo',
    value: 'COD',
    iconSrc: 'https://flagcdn.com/w320/cd.png',
    code: '+243',
  },
  {
    label: 'Montenegro',
    value: 'MNE',
    iconSrc: 'https://flagcdn.com/w320/me.png',
    code: '+382',
  },
  {
    label: 'Gibraltar',
    value: 'GIB',
    iconSrc: 'https://flagcdn.com/w320/gi.png',
    code: '+350',
  },
  {
    label: 'Somalia',
    value: 'SOM',
    iconSrc: 'https://flagcdn.com/w320/so.png',
    code: '+252',
  },
  {
    label: 'Sri Lanka',
    value: 'LKA',
    iconSrc: 'https://flagcdn.com/w320/lk.png',
    code: '+94',
  },
  {
    label: 'Cyprus',
    value: 'CYP',
    iconSrc: 'https://flagcdn.com/w320/cy.png',
    code: '+357',
  },
  {
    label: 'Laos',
    value: 'LAO',
    iconSrc: 'https://flagcdn.com/w320/la.png',
    code: '+856',
  },
  {
    label: 'Morocco',
    value: 'MAR',
    iconSrc: 'https://flagcdn.com/w320/ma.png',
    code: '+212',
  },
  {
    label: 'Timor-Leste',
    value: 'TLS',
    iconSrc: 'https://flagcdn.com/w320/tl.png',
    code: '+670',
  },
  {
    label: 'Yemen',
    value: 'YEM',
    iconSrc: 'https://flagcdn.com/w320/ye.png',
    code: '+967',
  },
  {
    label: 'Falkland Islands',
    value: 'FLK',
    iconSrc: 'https://flagcdn.com/w320/fk.png',
    code: '+500',
  },
  {
    label: 'Equatorial Guinea',
    value: 'GNQ',
    iconSrc: 'https://flagcdn.com/w320/gq.png',
    code: '+240',
  },
  {
    label: 'Algeria',
    value: 'DZA',
    iconSrc: 'https://flagcdn.com/w320/dz.png',
    code: '+213',
  },
  {
    label: 'Zambia',
    value: 'ZMB',
    iconSrc: 'https://flagcdn.com/w320/zm.png',
    code: '+260',
  },
  {
    label: 'Serbia',
    value: 'SRB',
    iconSrc: 'https://flagcdn.com/w320/rs.png',
    code: '+381',
  },
  {
    label: 'Andorra',
    value: 'AND',
    iconSrc: 'https://flagcdn.com/w320/ad.png',
    code: '+376',
  },
  {
    label: 'São Tomé and Príncipe',
    value: 'STP',
    iconSrc: 'https://flagcdn.com/w320/st.png',
    code: '+239',
  },
  {
    label: 'Djibouti',
    value: 'DJI',
    iconSrc: 'https://flagcdn.com/w320/dj.png',
    code: '+253',
  },
  {
    label: 'Bulgaria',
    value: 'BGR',
    iconSrc: 'https://flagcdn.com/w320/bg.png',
    code: '+359',
  },
  {
    label: 'United States Virgin Islands',
    value: 'VIR',
    iconSrc: 'https://flagcdn.com/w320/vi.png',
    code: '+1340',
  },
  {
    label: 'Argentina',
    value: 'ARG',
    iconSrc: 'https://flagcdn.com/w320/ar.png',
    code: '+54',
  },
  {
    label: 'Nigeria',
    value: 'NGA',
    iconSrc: 'https://flagcdn.com/w320/ng.png',
    code: '+234',
  },
  {
    label: 'Rwanda',
    value: 'RWA',
    iconSrc: 'https://flagcdn.com/w320/rw.png',
    code: '+250',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SHN',
    iconSrc: 'https://flagcdn.com/w320/sh.png',
    code: '+290,47',
  },
  {
    label: 'Canada',
    value: 'CAN',
    iconSrc: 'https://flagcdn.com/w320/ca.png',
    code: '+1',
  },
  {
    label: 'North Korea',
    value: 'PRK',
    iconSrc: 'https://flagcdn.com/w320/kp.png',
    code: '+850',
  },
  {
    label: 'Åland Islands',
    value: 'ALA',
    iconSrc: 'https://flagcdn.com/w320/ax.png',
    code: '+35818',
  },
  {
    label: 'North Macedonia',
    value: 'MKD',
    iconSrc: 'https://flagcdn.com/w320/mk.png',
    code: '+389',
  },
  {
    label: 'Netherlands',
    value: 'NLD',
    iconSrc: 'https://flagcdn.com/w320/nl.png',
    code: '+31',
  },
  {
    label: 'Niger',
    value: 'NER',
    iconSrc: 'https://flagcdn.com/w320/ne.png',
    code: '+227',
  },
  {
    label: 'Singapore',
    value: 'SGP',
    iconSrc: 'https://flagcdn.com/w320/sg.png',
    code: '+65',
  },
  {
    label: 'Chad',
    value: 'TCD',
    iconSrc: 'https://flagcdn.com/w320/td.png',
    code: '+235',
  },
  {
    label: 'Curaçao',
    value: 'CUW',
    iconSrc: 'https://flagcdn.com/w320/cw.png',
    code: '+599',
  },
  {
    label: 'Niue',
    value: 'NIU',
    iconSrc: 'https://flagcdn.com/w320/nu.png',
    code: '+683',
  },
  {
    label: 'Guinea-Bissau',
    value: 'GNB',
    iconSrc: 'https://flagcdn.com/w320/gw.png',
    code: '+245',
  },
  {
    label: 'Nauru',
    value: 'NRU',
    iconSrc: 'https://flagcdn.com/w320/nr.png',
    code: '+674',
  },
  {
    label: 'Zimbabwe',
    value: 'ZWE',
    iconSrc: 'https://flagcdn.com/w320/zw.png',
    code: '+263',
  },
  {
    label: 'China',
    value: 'CHN',
    iconSrc: 'https://flagcdn.com/w320/cn.png',
    code: '+86',
  },
  {
    label: 'Christmas Island',
    value: 'CXR',
    iconSrc: 'https://flagcdn.com/w320/cx.png',
    code: '+61',
  },
  {
    label: 'Puerto Rico',
    value: 'PRI',
    iconSrc: 'https://flagcdn.com/w320/pr.png',
    code: '+1787,939',
  },
  {
    label: 'Ethiopia',
    value: 'ETH',
    iconSrc: 'https://flagcdn.com/w320/et.png',
    code: '+251',
  },
  {
    label: 'Grenada',
    value: 'GRD',
    iconSrc: 'https://flagcdn.com/w320/gd.png',
    code: '+1473',
  },
  {
    label: 'Finland',
    value: 'FIN',
    iconSrc: 'https://flagcdn.com/w320/fi.png',
    code: '+358',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'TCA',
    iconSrc: 'https://flagcdn.com/w320/tc.png',
    code: '+1649',
  },
  {
    label: 'Burundi',
    value: 'BDI',
    iconSrc: 'https://flagcdn.com/w320/bi.png',
    code: '+257',
  },
  {
    label: 'Guam',
    value: 'GUM',
    iconSrc: 'https://flagcdn.com/w320/gu.png',
    code: '+1671',
  },
  {
    label: 'Jordan',
    value: 'JOR',
    iconSrc: 'https://flagcdn.com/w320/jo.png',
    code: '+962',
  },
  {
    label: 'Mali',
    value: 'MLI',
    iconSrc: 'https://flagcdn.com/w320/ml.png',
    code: '+223',
  },
  {
    label: 'Ukraine',
    value: 'UKR',
    iconSrc: 'https://flagcdn.com/w320/ua.png',
    code: '+380',
  },
  {
    label: 'Réunion',
    value: 'REU',
    iconSrc: 'https://flagcdn.com/w320/re.png',
    code: '+262',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'SJM',
    iconSrc: 'https://flagcdn.com/w320/sj.png',
    code: '+4779',
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'CCK',
    iconSrc: 'https://flagcdn.com/w320/cc.png',
    code: '+61',
  },
  {
    label: 'Tanzania',
    value: 'TZA',
    iconSrc: 'https://flagcdn.com/w320/tz.png',
    code: '+255',
  },
  {
    label: 'Qatar',
    value: 'QAT',
    iconSrc: 'https://flagcdn.com/w320/qa.png',
    code: '+974',
  },
  {
    label: 'Brazil',
    value: 'BRA',
    iconSrc: 'https://flagcdn.com/w320/br.png',
    code: '+55',
  },
  {
    label: 'Sudan',
    value: 'SDN',
    iconSrc: 'https://flagcdn.com/w320/sd.png',
    code: '+249',
  },
  {
    label: 'Romania',
    value: 'ROU',
    iconSrc: 'https://flagcdn.com/w320/ro.png',
    code: '+40',
  },
  {
    label: 'Anguilla',
    value: 'AIA',
    iconSrc: 'https://flagcdn.com/w320/ai.png',
    code: '+1264',
  },
  {
    label: 'Iraq',
    value: 'IRQ',
    iconSrc: 'https://flagcdn.com/w320/iq.png',
    code: '+964',
  },
  {
    label: 'Bhutan',
    value: 'BTN',
    iconSrc: 'https://flagcdn.com/w320/bt.png',
    code: '+975',
  },
  {
    label: 'Honduras',
    value: 'HND',
    iconSrc: 'https://flagcdn.com/w320/hn.png',
    code: '+504',
  },
  {
    label: 'Namibia',
    value: 'NAM',
    iconSrc: 'https://flagcdn.com/w320/na.png',
    code: '+264',
  },
  {
    label: 'Slovenia',
    value: 'SVN',
    iconSrc: 'https://flagcdn.com/w320/si.png',
    code: '+386',
  },
  {
    label: 'Haiti',
    value: 'HTI',
    iconSrc: 'https://flagcdn.com/w320/ht.png',
    code: '+509',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BIH',
    iconSrc: 'https://flagcdn.com/w320/ba.png',
    code: '+387',
  },
  {
    label: 'Greenland',
    value: 'GRL',
    iconSrc: 'https://flagcdn.com/w320/gl.png',
    code: '+299',
  },
  {
    label: 'Jamaica',
    value: 'JAM',
    iconSrc: 'https://flagcdn.com/w320/jm.png',
    code: '+1876',
  },
  {
    label: 'Malta',
    value: 'MLT',
    iconSrc: 'https://flagcdn.com/w320/mt.png',
    code: '+356',
  },
  {
    label: 'Bolivia',
    value: 'BOL',
    iconSrc: 'https://flagcdn.com/w320/bo.png',
    code: '+591',
  },
];

const APIS_WITH_LOCAL_ERROR_HANDLING = [
  `${process.env.REACT_APP_REMOTE}/api_v2/validateCPF`,
  `${process.env.REACT_APP_REMOTE}/api_v2/authenticatePlayer`,
  `${process.env.REACT_APP_REMOTE}/api_v2/resetPassword`,
  `${process.env.REACT_APP_REMOTE}/api_v2/validateRegistrationField`,
  'https://gameboxapi.draft10.com/api/error',
  'https://strapi4papigames.draftplaza.com/api',
  'https://prod20211-105491172.bti-sports.io',
  'https://tslotsgameboxapi.draftplaza.com/api/error',
  'https://webapi.draftplaza.com/api_v2/getBalance',
  'https://rcgameboxmodule.draftplaza.com',
  '/info-page-categories',
  '/custom-i18n',
  '/public-popups',
];

const AVATAR_DEFAULT_IMAGE = 'https://f0.pngfuel.com/png/980/886/male-portrait-avatar-png-clip-art.png';

const SPECIAL_TAGS = ['last5Games'];

const GAMES_COUNT_PER_PAGE_MOBILE = 24;
const GAMES_COUNT_PER_PAGE_DESKTOP = 28;

const BACKGROUND = [
  //  eslint-disable-next-line
  { name: 'bg-surface-1', value: '--background-surface-1' },
  //  eslint-disable-next-line
  { name: 'bg-surface-2', value: '--background-surface-2' },
  { name: 'bg-surface-3', value: '--background-surface-3' },
  { name: 'navigation-bg', value: '--navigation-background' },
  { name: 'footer-bg', value: '--footer-background' },
  { name: 'account-bg-surface-1', value: '--account-background-surface-1' },
  { name: 'account-bg-surface-2', value: '--account-background-surface-2' },
  { name: 'account-bg-surface-3', value: '--account-background-surface-3' },
];

const BORDER_SECTION = [
  { name: 'primary', value: '--border-primary' },
  { name: 'Primary-hover', value: '--border-primary-hover' },
  { name: 'primary-selected', value: '--border-primary-selected' },
];

const INPUT_SECTION = [
  { name: 'bg-surface-1', value: '--input-background-surface-1' },
  { name: 'bg-disable', value: '--input-background-disable' },

  { name: 'border-default', value: '--input-border-default' },
  { name: 'border-hover', value: '--input-border-hover' },
  { name: 'border-typing', value: '--input-border-typing' },
  { name: 'border-error', value: '--input-border-error' }, // цвета для еррора разве не всегда должны быцть красными?
  { name: 'border-disable', value: '--input-border-disable' },
];

const SEARCH_SECTION = [
  { name: 'bg-surface-1', value: '--search-background-surface-1' },
  { name: 'bg-surface-2', value: '--search-background-surface-2' },
  { name: 'border', value: '--search-border' },
  { name: 'border-selected', value: '--search-border-selected' },
];

const TEXT_SECTION = [
  { name: 'primary', value: '--text-primary' },
  { name: 'Primary-hover', value: '--text-primary-hover' },
  { name: 'secondary', value: '--text-secondary' },
  { name: 'warning', value: '--text-warning' },
  { name: 'error', value: '--text-error' },
  { name: 'brand', value: '--text-brand' },
  { name: 'disable', value: '--text-disable' },
  { name: 'tag', value: '--text-tag' },
  { name: 'navigation', value: '--navigation-text' },
  { name: 'navigation-hover', value: '--navigation-text-hover' },
];

const BUTTON_SECTION = [
  { name: 'primary', value: '--button-primary' },
  { name: 'primary-hover', value: '--button-primary-hover' },
  { name: 'primary-text', value: '--button-primary-text' },
  { name: 'primary-border', value: '--button-primary-border' },

  { name: 'secondary', value: '--button-secondary' },
  { name: 'secondary-hover', value: '--button-secondary-hover' },
  { name: 'secondary-text', value: '--button-secondary-text' },
  { name: 'secondary-border', value: '--button-secondary-border' },
  { name: 'secondary-border-hover', value: '--button-secondary-border-hover' },

  { name: 'brand', value: '--button-brand' },
  { name: 'brand-hover', value: '--button-brand-hover' },
  { name: 'brand-text', value: '--button-brand-text' },
  { name: 'brand-border', value: '--button-brand-border' },
  { name: 'brand-border-hover', value: '--button-brand-border-hover' },

  { name: 'ghost', value: '--button-ghost' },
  { name: 'ghost-hover', value: '--button-ghost-hover' },
  { name: 'ghost-text', value: '--button-ghost-text' },

  { name: 'Disable', value: '--button-disable' },
  { name: 'disable-text', value: '--button-disable-text' },
];

const TAG_SECTION = [
  { name: 'primary', value: '--tag-primary' },
  { name: 'primary-hover', value: '--tag-primary-hover' },
  { name: 'primary-selected', value: '--tag-primary-selected' },
  { name: 'primary-border', value: '--tag-primary-border' },
  { name: 'primary-hover-border', value: '--tag-primary-hover-border' },
  { name: 'primary-selected-border', value: '--tag-primary-selected-border' },
];

const ICON_SECTION = [
  { name: 'primary', value: '--icon-color-primary' },
  { name: 'secondary', value: '--icon-color-secondary' },
  { name: 'passive', value: '--icon-color-passive' },
];

const ONE_HOUR = 3600000;
const ONE_MINUTE = 60000;

const RAF_LIMIT = 10;

const PAPIGAMES_AFFILATES = [
  { name: 'yuri22', id: '1331867' },
  { name: 'dinhutech', id: '560630' },
  { name: 'luva', id: '1600084' },
  { name: 'gabepeixe', id: '1570494' },
  { name: 'facada', id: '1570487' },
  { name: 'carmona', id: '1085576' },
  { name: 'moraes', id: '560635' },
  { name: 'snopey', id: '1632854' },
  { name: 'piuzinho', id: '1632900' },
  { name: 'xakaall', id: '1633328' },
  { name: 'caiox', id: '1634429' },
  { name: 'meikod', id: '1636897' },
];

export {
  PAPIGAMES_AFFILATES,
  RAF_LIMIT,
  ONE_HOUR,
  ONE_MINUTE,
  APP_API,
  APP_API_VERSION,
  AVATAR_DEFAULT_IMAGE,
  BACKGROUND,
  BASE_URL,
  BORDER_SECTION,
  BUTTON_SECTION,
  CARDEQUALSIZE,
  CAROUSEL_DESKTOP_HEIGHT,
  CAROUSEL_DESKTOP_IMG_HEIGHT,
  CAROUSEL_DESKTOP_MOBILE,
  CAROUSEL_MOBILE_IMG_HEIGHT,
  CATEGORIES,
  CMS_BANNERS_CATEGORY_CONVERT,
  CMS_CONVERTER,
  COLS,
  COUNTRY_LIST,
  CURRENCY_SYMBOLS,
  DEFAULT_INACTIVITY_TIME,
  EnumLangCodes,
  EnumPaymentType,
  FIELD_OPTIONS,
  GAME_BOX_EL_LIST,
  GAME_CARD_SIZES,
  GAME_MOVEMENTS,
  GAME_TAGS_SCROLL_COUNT,
  GAME_VISIABLE_COUNT_FOR_DESKTOP,
  GAME_VISIABLE_COUNT_FOR_MOBILE,
  GAMES_COUNT_PER_PAGE_DESKTOP,
  GAMES_COUNT_PER_PAGE_MOBILE,
  GENERATE_LINK_SOCIAL_ICONS,
  HAS_HOME_PAGE,
  HISTORY_REQ_LIMIT,
  ICON_SECTION,
  IFRAMES,
  INPUT_SECTION,
  LANGUAGES_CODES,
  MOBILE_IMG_SIZE,
  MOBILE_MENU_PROMOTIONS,
  NAVIGATION_GAMES_URLS,
  PAGES_WITHOUT_MOBILE_FIXED_MENU,
  PAYMENT_TYPES,
  PERIOD_FILTER_TYPES,
  PERSONAL_INFO_FIELD_DESKTOP,
  PERSONAL_INFO_FIELD_MOBILE,
  PRIZE_DISTRIBUTION_TABLE_CAPTIONS,
  REG_number,
  ROWS,
  SEARCH_SECTION,
  SIGN_UP_PLACEHOLDERS,
  SILVER_CASINO_CLOSE_MESSAGE,
  SLIDER_DEFAULT_MAX_COUNT,
  SLIDER_MOBILE_COUNT,
  SOCIAL_MEDIA_SHARER,
  SPECIAL_TAGS,
  SPORT_CATEGORIES,
  STATIC_IFRAME_URL,
  SUCCESS_STATUSES,
  TABLE_CAPTION_ITEMS,
  TAG_SECTION,
  TEMPLATE_1_SLIDER_GAMES_COUNT,
  TEMPLATE_2_SLIDER_GAMES_COUNT,
  TEMPLATE_5_SLIDER_GAMES_COUNT,
  TEMPLATE_BANNNER_RADIUS,
  TEMPLATE_RADIUS,
  TEMPLATES_WITH_DETAILS,
  TEXT_SECTION,
  THEMES,
  TRANSACTION_TYPES,
  TRANSACTIONS_HEADER,
  TRANSACTIONS_MOVEMENTS,
  TYPOGRAPHY_VARIATIONS,
  VIEW_CATEGORY_TRANSFORM,
  WIDGET_STYLES_FOR_2_3,
  WIDGET_STYLES_FOR_3_1,
  WIN_SLIDER_COUNT_DESKTOP,
  WIN_SLIDER_COUNT_MOBILE,
  APIS_WITH_LOCAL_ERROR_HANDLING,
  XPRESS_PROVIDER_WITH_REDIRECT_HOME,
};
