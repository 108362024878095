import { FC, useEffect, useState } from 'react';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { getRowNumber } from 'src/helpers/utils';
import { RootState } from 'src/types/store-types';
import { getLastPlayedGamesData } from '../../../../services/gameApi';
import Template1 from '../components/Template1';
import Template2 from '../components/Template2';
import HomePageGamesSlider from './HomePageGamesSlider';

type Props = {
  settings: {
    prioiry: number;
    data: any[];
  };
};

type ITagsDataProps = {
  category: string;
  limit: number;
  tagName: string;
  trTagName: string;
  template: string;
};

const HomePageGamesSliders: FC<Props> = ({ settings }) => {
  const { data } = settings;
  const { pageTagsPreviews } = useSelector((state: RootState) => state.configs);
  const [tagsData, setTagsData] = useState<ITagsDataProps[]>([]);
  const lng = localStorage.getItem('selectedLanguage');
  const [lastPlayedGames, setLastPlayedGames] = useState<[]>([]);
  const { user } = useSelector((state: RootState) => state.user);
  const showPlayedGAmes: boolean = process.env.REACT_APP_SHOW_PLAYED_GAMES === 'true';

  useEffect(() => {
    if (pageTagsPreviews && lng) {
      const newTagsData = pageTagsPreviews.map((item: Record<string, string>) => ({
        category: item.category,
        limit: 24,
        tagName: item.tag,
        trTagName: item.translations[lng],
        template: 'template_' + getRowNumber(item.viewType),
        priority: item.priority,
      }));
      setTagsData(newTagsData);
    }
  }, [lng]);

  const getLastPlayedGames = (): void => {
    getLastPlayedGamesData().then((res) => {
      if (res) {
        setLastPlayedGames(res.result['games']);
      }
    });
  };

  useEffect(() => {
    if (user && showPlayedGAmes) {
      getLastPlayedGames();
    }
  }, [user, showPlayedGAmes]);

  return (
    <div className="home_slider_container">
      {lastPlayedGames.length && user && showPlayedGAmes && (
        <>
          {isDesktop && lastPlayedGames.length >= 12 ? (
            <Template1 activeGames={lastPlayedGames} tagName="keep_playing" />
          ) : (
            (isMobileOnly || lastPlayedGames.length < 12) && (
              <Template2 activeGames={lastPlayedGames} tagName="keep_playing" />
            )
          )}
        </>
      )}
      {[...data, ...tagsData]
        ?.sort((a: { priority: number }, b: { priority: number }) => b?.priority - a?.priority)
        ?.map((m) => {
          return (
            <HomePageGamesSlider
              key={m.tagName}
              category={m.category || 'none'}
              tagName={m.tagName}
              trTagName={m?.trTagName}
              template={m.template}
              limit={m.limit}
              showViewAllLink={m.category !== null}
            />
          );
        })}
    </div>
  );
};
export default HomePageGamesSliders;
