import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import GeneralLoader from 'src/components/common/GeneralLoader';
import { Typography } from 'src/components/common/Typography';
import { envBoolean, getFromQueryParams, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setGeneraLoading } from 'src/store/app/actions';
import { setCategoriesData, setGeneralConfigs, setPageConfigs } from 'src/store/configs/actions';
import { RootState } from 'src/types/store-types';
import { IFRAMES } from 'src/utils/constants';
import './styles.scss';

const GeneralLoaderPage: FC = () => {
  const { search } = useLocation();

  const DEEP_LINKS = ['raf', 'deposit'];

  const { t } = useTranslation();
  const params = getFromQueryParams(search);

  const { generalLoading } = useSelector((state: RootState) => state.app);

  const _setCategoriesData = useConnectedAction(setCategoriesData.req);
  const _setPageConfigs = useConnectedAction(setPageConfigs.req);
  const _setGeneraLoading = useConnectedAction(setGeneraLoading);
  const _getGeneralConfigs = useConnectedAction(setGeneralConfigs.req);

  const { selectedCategory } = useSelector((state: RootState) => state.app);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getMainAPIs = (): void => {
    setIsLoading(true);

    Promise.all([_getGeneralConfigs(), _setPageConfigs(), _setCategoriesData()])
      .then(() => {
        !generalLoading.includes(false) &&
          setTimeout(
            () => {
              _setGeneraLoading(false);
              if (params.affiliateId || (sessionStorage.getItem('affiliateParams') && isMobile)) {
                setBodyOverflow('unset');
              } else {
                setBodyOverflow('set');
              }
              if (params.gbBonusCode || (sessionStorage.getItem('gbBonusCode') && isMobile)) {
                setBodyOverflow('unset');
              } else {
                setBodyOverflow('set');
              }
              if (DEEP_LINKS.includes(params.navigateTo) && isMobile) {
                setBodyOverflow('unset');
              }
              setIsLoading(false);
            },
            envBoolean(process.env.REACT_APP_GENERAL_LOADER_IS_GIF as string) ? 7000 : 3000
          );
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
        setBodyOverflow(IFRAMES.includes(selectedCategory) ? 'unset' : 'set');
      });
  };

  useEffect(() => {
    getMainAPIs();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setBodyOverflow('set');
    }
  }, [isLoading]);

  return (
    <div className="general_loader_page_container">
      {isLoading && <GeneralLoader />}
      {hasError && (
        <div className="error_wrapper">
          <Typography className="text_1" variant="subtitle1">
            Oops! {t('smth_went_wrong')}!
          </Typography>
          <Typography className="text_1" variant="subtitle1">
            {t('please_try_again')}!
          </Typography>
          <Button className="primary-filled-btn back_to_home" onClick={getMainAPIs}>
            {t('reload')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default GeneralLoaderPage;
