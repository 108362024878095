import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { Typography } from 'src/components/common/Typography';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { setSelectedGame } from 'src/store/games/actions';
import { forceCloseGameBox } from 'src/store/integrations/actions';
import './styles.scss';

interface IProps {
  data?: any;
}
const GameUrlFailure: FC<IProps> = () => {
  const { t }: Translation = useTranslation();

  setBodyOverflow('unset');

  const _selectedGame = useConnectedAction(setSelectedGame);
  const _forceCloseGameBox = useConnectedAction(forceCloseGameBox);

  const _closeDialog = useConnectedAction(closeDialog);

  const removeDialog = (): void => {
    setBodyOverflow('set');
    _closeDialog({ dialogType: EnumDialogsKeys.GAME_URL_FAILURE });
    _selectedGame(null);
    _forceCloseGameBox(true);
  };

  return (
    <PopUpContainer
      isOpen
      handleClose={removeDialog}
      handleOK={removeDialog}
      hasOverlay={false}
      title={t('gameUrlFailureTitle')}
      className="game-url-failure-popup"
    >
      <div className="description-wrapper">
        <Typography variant={'h5'} className="title">
          {t('gameUrlFailureMessage')}
        </Typography>
      </div>
    </PopUpContainer>
  );
};

export default GameUrlFailure;
