import React, { FC, useEffect, useMemo, useState } from 'react';
import { isTablet } from 'react-device-detect';
import { useSelector } from 'react-redux';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { detectDevice, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { getAuthImages } from 'src/services/dataApi';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { RootState } from 'src/types/store-types';
import { IModal } from '../../../../types/common-types/modal';
import { IForgotRes, ISignInRes, ISignUpRes } from '../../../../types/res-dto/authorization';
import ContentWithImage from './components/ContentWithImage';
import ContentWithoutImage from './components/ContentWithoutImage';
import ForgotPassContent from './components/ForgotPassContent';
import LoginContent from './components/LoginContent';
import SignUpContent from './components/SignUpContent';
import './styles.scss';

interface IProps extends IModal {
  loginModalData: ISignInRes;
  forgotModalData: IForgotRes;
  signUpModalData: ISignUpRes;
  content: 'login' | 'forgot' | 'registration' | null;
  setModalContent: (action: 'open' | 'close', content: 'login' | 'registration' | 'forgot' | null) => void;
}

const AuthModal: FC<IProps> = ({
  visible,
  close,
  loginModalData,
  forgotModalData,
  signUpModalData,
  content,
  setModalContent,
  hasTitle,
}) => {
  const device = detectDevice();

  const _openAuthModal = useConnectedAction(openAuthModal);

  const { showMenu } = useSelector((state: RootState) => state.mobile);

  const [modalTitle, setModalTitle] = useState<string>('');
  const [imgSrc, setImgSrc] = useState<string>('');

  const modalContentsMap = useMemo(
    () => ({
      login: (
        <LoginContent
          close={close}
          username={loginModalData.username}
          password={loginModalData.password}
          email={loginModalData.email}
          radio={loginModalData.radio}
          cpf={loginModalData.cpf}
          rememberMe={loginModalData.rememberMe}
          forgotLink={loginModalData?.forgotLink}
          signUp={loginModalData.signUp}
          setContent={setModalContent}
        />
      ),
      forgot: (
        <ForgotPassContent
          close={close}
          username={forgotModalData.username}
          email={forgotModalData.email}
          signUp={forgotModalData.signUp}
          signIn={forgotModalData.signIn}
          setContent={setModalContent}
        />
      ),
      registration: (
        <SignUpContent
          close={close}
          setContent={setModalContent}
          forgotLink={signUpModalData?.forgotLink}
          signIn={signUpModalData?.signIn}
          registrationSteps={signUpModalData?.registrationSteps}
          styles={signUpModalData?.styles}
        />
      ),
    }),
    [loginModalData, forgotModalData, signUpModalData, content]
  );

  const variants = useMemo(
    () => ({
      login: loginModalData?.type,
      forgot: forgotModalData?.type,
      registration: signUpModalData?.type,
    }),
    [loginModalData, forgotModalData, signUpModalData]
  );

  const toggleModal = (): void => {
    !showMenu && setBodyOverflow('unset');
    _openAuthModal('login');
  };

  const getAuthImgSrc = (): void => {
    setImgSrc('');

    !isTablet &&
      getAuthImages().then((res: any) => {
        if (!!res?.data) {
          setImgSrc(
            process.env.REACT_APP_FILE_STORAGE_ADDRESS +
              res.data?.attributes[
                device + (content as string).charAt(0).toUpperCase() + (content as string).slice(1) + 'Banners'
              ].data?.[0].attributes.url.substring(1)
          );
        }
      });
  };

  useEffect(() => {
    visible && content && variants?.[content as string] === 'withImage' && getAuthImgSrc();
  }, [visible, content, variants]);

  useEffect(() => {
    if (hasTitle) {
      setModalTitle(content as string);
    }
  }, [content, hasTitle]);

  return (
    <PopUpContainer
      isOpen={visible}
      hasOverlay
      childerHasImage={variants[content as string]}
      type="withImage"
      handleClose={close}
    >
      {variants[content as string] === 'withImage' ? (
        <ContentWithImage
          modalContentsMap={modalContentsMap}
          content={content}
          imgSrc={imgSrc}
          modalTitle={modalTitle}
        />
      ) : (
        <ContentWithoutImage
          toggleModal={toggleModal}
          modalTitle={modalTitle}
          modalContentsMap={modalContentsMap}
          content={content}
        />
      )}
    </PopUpContainer>
  );
};

export default AuthModal;
